<template>
  <div>
    <!-- Populate Menu items -->
    <v-card class="mx-auto" max-width="500">
      <v-list>
          <v-list-item
            v-for="(item, i) in filteredItems"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-card>
    <!-- Populate Menu items -->
  </div>
</template>
  
<script type="text/babel">

import { EventBus } from '../../EventBus';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ApwManagePlanAction",
  data() {
    return {
      planStatusConfirm: false,
    }
  },
  props: {
    propertyId: {
      type: String,
      default: "",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission',
    }),
    // Filter items based on permission
    filteredItems() {
      return this.items.filter(item => {
        return item.permission ? this.hasPermission(item.permission) : true;
      });
    },
    items() {
        return [
            {
                text: "Assign In-store Plan",
                event: "assignInstorePlan",
                permission: 'assign_plans',
            },
            {
                text: "Assign Online Plan",
                event: "assignOnlinePlan",
                permission: 'assign_plans',
            },
            {
              text: "View Spaces",
              event: "viewSpaces",
            },
            {
              text: "Enable/Disable Advanced Reservations and Rentals Management",
              event: "planStatus",
              permission: 'activate_or_deactivate_plans',
            }
        ]
    },
  },
  methods: {
    ...mapActions({
      setSpaceData: "revManStore/setSpaceData",
    }),
    triggerEvent(eventType) {
      if (eventType == "viewSpaces") {
        this.setSpaceData(
          {
            ...this.params?.data,
            ...{ propertyId: this.propertyId }
          }
        );
      }else {
        EventBus.$emit("apwManagementByPropertyEvent", {
          eventType,
          data: this.params.data,
        });
      }
    },
  },
};
</script>